<template>
    <div class="supplier-details" v-if="fetched && supplierData">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Informationen" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-1/3">
                                    <strong>Kundennummer:</strong> {{supplierData.customer_number}}
                                </div>
                              <div class="vx-col lg:w-2/3">
                                <vs-input class="w-full" label="Externe Lieferanten-Nummer (Unsere Kundennummer beim Lieferanten)" name="supplier_number"
                                                                               v-model="supplierData.supplier_number" type="text"/>
                              </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Firma *" data-vv-as="Firma" name="company_name"
                                              v-model="supplierData.company_name" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('company_name')">{{ errors.first('company_name') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 1 *" data-vv-as="Anschrift 1"
                                              name="address_line_1" v-model="supplierData.address_line_1" type="text"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('address_line_1')">{{ errors.first('address_line_1') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 2" data-vv-as="Anschrift 2"
                                              name="address_line_2" v-model="supplierData.address_line_2" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Postleitzahl *" data-vv-as="Postleitzahl"
                                              name="zip_code" v-model="supplierData.zip_code" type="text"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
                                </div>

                                <div class="vx-col lg:w-2/3">
                                    <vs-input class="w-full" label="Standort *" data-vv-as="Standort" name="city"
                                              v-model="supplierData.city" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('city')">{{ errors.first('city') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-select label="Land" class="w-full" v-model="supplierData.country">
                                        <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                        v-for="item, index in countries"/>
                                    </vs-select>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Telefon" data-vv-as="Telefon" name="phone"
                                              v-model="supplierData.phone" type="text"/>
                                </div>

                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Fax" data-vv-as="Fax" name="fax"
                                              v-model="supplierData.fax" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="E-Mail-Adresse *" data-vv-as="E-Mail-Adresse"
                                              name="email" v-model="supplierData.email" type="email"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Weiter Details" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Webseite" data-vv-as="Webseite" name="website"
                                              v-model="supplierData.websites" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Steuernummer" data-vv-as="Steuernummer"
                                              name="ust_id" v-model="supplierData.ust_id" type="text"
                                              v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Payment Deadline" data-vv-as="Payment Deadline"
                                              name="payment_deadline" v-model="supplierData.payment_deadline"
                                              type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="IBAN" data-vv-as="IBAN" name="bank_iban"
                                              v-model="supplierData.bank_iban" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="SWIFT/BIC" data-vv-as="SWIFT/BIC" name="bank_bic"
                                              v-model="supplierData.bank_bic" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Kontoinhaber" data-vv-as="Kontoinhaber"
                                              name="bank_account_owner" v-model="supplierData.bank_account_owner"
                                              type="text"/>
                                </div>
                            </div>

                          <div class="vx-col lg:w-full mt-5">
                            <h3>Anleitung für Rechnungseingang</h3>
                            <label for="comment" class="vs-input--label">Hinweise wie: Wie kommt die Rechnungs an uns (Post/Mail), an welche E-Mail, Login in ein Portal notwendig? Über welche E-Mail, Wie komme ich zu der Rechnung? Was ist zu beachten?</label>
                            <vs-textarea class="w-full"
                                         data-vv-as="nur für Interne Zwecke"
                                         name="comment"
                                         id="comment"
                                         height="100px"
                                         v-model="supplierData.supplier_invoice_portal_instructions">
                            </vs-textarea>
                          </div>

                            <div class="vx-col lg:w-full mt-5">
                                <h3>Interne Notiz</h3>
                                <label for="comment" class="vs-input--label">nur für Interne Zwecke</label>
                                <vs-textarea class="w-full"
                                             data-vv-as="nur für Interne Zwecke"
                                             name="comment"
                                             id="comment"
                                             height="200px"
                                             v-model="supplierData.internal_notice">
                                </vs-textarea>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <ClientBankTransactionStatistic :client-id="supplierData.id"></ClientBankTransactionStatistic>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'suppliers'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveClient">Speichern</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import staticOptions from "@/mixins/static/options";
    import ClientBankTransactionStatistic from "@/components/client/ClientBankTransactionStatistic.vue";

    export default {
        components: {ClientBankTransactionStatistic},
        data() {
            return {
                countries: [],
                fetched: false,
                supplierData: null
            }
        },
        mounted() {
            this.fetchCountries();
            this.fetchClientData();
        },
        methods: {
            fetchCountries() {
                this.countries = staticOptions.countries;
            },
            fetchClientData() {
                ApiService.get('clients/' + this.$route.params.id)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fetched = true;
                            this.supplierData = response.data.result;
                        } else {
                            this.$router.push({name: 'suppliers'});
                        }
                    })
                    .catch((error) => {
                        this.$router.push({name: 'suppliers'});
                    })
            },

            saveClient() {
                let payload = this.supplierData;

                delete payload.customer_number;

                ApiService.put('clients/' + this.$route.params.id, payload)
                    .then((response) => {
                        if(response.data.status === 'success') {
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Lieferant wurde erfolgreich bearbeitet',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Lieferant konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Lieferant konnte nicht bearbeitet werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })
            }
        },
    }
</script>
