<template>
    <div>
        <vx-card title="Belege des Lieferanten" class="h-full client-bank-transaction-statistic"
                 v-if="userHasPermission('view_bank_transactions')">
            <vue-apex-charts type="area" height="200" width="1550" :options="chartOptions" :series="series"></vue-apex-charts>
        </vx-card>
        <vx-card title="Liste aller Belege:" class="h-full client-bank-transaction-statistic "
                 v-if="userHasPermission('view_bank_transactions')">
            <vue-bootstrap4-table :rows="data"
                                  :columns="columns"
                                  :config="config"
                                  :actions="actions"
                                  @on-change-query="onChangeQuery"
                                  @on-change-per-page="onChangePerPage"
                                  class="scrollable mt-3"
                                  columnSelection
            >

                <template slot="row_actions" slot-scope="props">
                    <vs-button color="dark" type="filled" class="mr-3 mb-auto" size="small"
                               target="_blank"
                               :href="`${props.row.file_url}`"
                               v-if="props.row.file">
                        PDF
                    </vs-button>
                </template>

                <template slot="file_thumb_url" slot-scope="props">
                    <a class="document-zoom" :href="props.row.file_thumb_url" target="_blank">
                        <img :src="props.row.file_thumb_url" v-if="props.row.file_thumb_url" class="document-preview"
                             alt="">
                    </a>
                </template>

                <template slot="id" slot-scope="props">
                    {{ props.row.id }}
                </template>

                <template slot="document_date" slot-scope="props">
                         <span>
                           {{ formatDate(props.row.document_date) }}
                         </span>
                </template>

                <template slot="document_number" slot-scope="props">
                         <span>
                           {{ props.row.document_number }}
                         </span>
                </template>

                <template slot="sum_netto" slot-scope="props">
                         <span>
                           {{props.row.booking_type}}{{ formatCurrency(props.row.sum_netto) }}
                         </span>
                </template>

                <template slot="booking_type" slot-scope="props">
                         <span v-if="props.row.booking_type == '-'">
                           Ausgabe
                         </span>
                         <span v-if="props.row.booking_type == '+'">
                           Einnahme
                         </span>
                </template>

                <template slot="sum_brutto" slot-scope="props">
                         <span>
                             {{ formatCurrency(props.row.sum_netto) }}
                         </span>
                </template>

                <template slot="bank_transaction_id" slot-scope="props">
                    <small v-if="props.row.bank_transaction_id != null">
                        Zugewiesen am: {{ formatDate(props.row.bank_transaction.booking_date) }}<br>
                        Betrag: {{ formatCurrency(props.row.bank_transaction.sum) }}<br>
                        Verwendungszweck: {{ props.row.bank_transaction.reference_text }}<br>
                    </small>
                    <span v-else>- nicht zugewiesen -</span>
                </template>


                <template slot="empty-results">
                    Keine Dokumente gefunden.
                </template>
                <template slot="table-last-row">
                    <tr class="vbt-row-summary">
                        <td colspan="2">
                            <span class="vbt-table-td">{{ total_rows }} Ergebnisse</span>
                        </td>
                        <td colspan="3" align="right">
                        </td>
                        <td colspan="3" width="300px">
                            <p class="vbt-table-td pr-8" style="text-align: right;">
                                Einnahmen: {{ pageSumIncome }}<br>
                                Ausgaben: {{ pageSumExpenses }}<br>
                                <b>Gesamt: {{ pageSum }}</b>
                            </p>
                        </td>
                    </tr>
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
import ApiService from "../../api";
import staticOptions from "../../mixins/static/options";
import MgSelect from "../../components/mg-select/MgSelect";
import * as qs from "qs";
import moment from 'moment';
import PriceHelper from "../../mixins/helper/price.helper";
import {mapGetters} from "vuex";
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'
import QueryHelper from "@/mixins/helper/query.helper";
import VueApexCharts from "vue-apexcharts";

export default {
    name: "ClientBankTransactionStatistic",
    components: {
        VueApexCharts,
        MgSelect,
        VueBootstrap4Table
    },
    props: {
        clientId: {
            type: Number,
        },
    },
    data() {
        return {

            /*
      Chart
      */
            chartData: [],
            series: [],
            chartOptions: {
                chart: {
                    width: '100%',
                    height: 250,
                    type: 'area'
                },
                tooltip: {},
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                labels: ["2"],
                xaxis: {
                    type: 'string',
                    categories: [],

                },

            },

            /* table */
            total_rows: 0,
            data: [],
            actions: [
            ],
            columns: [
                {
                    label: "Vorschau",
                    name: "file_thumb_url",
                    slot_name: "file_thumb_url",
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "ID",
                    name: "id",
                    filter: {
                        type: "simple",
                        placeholder: "ID suchen..."
                    },
                    slot_name: "id",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false,
                },
                {
                    label: "Belegdatum",
                    name: "document_date",
                    formatter: (value) => {
                        return moment(new Date(value)).format('DD.MM.YYYY');
                    },
                    slot_name: "document_date",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    filter: {
                        type: 'date',
                        mode: 'range'
                    },
                }, {
                    label: "Dokument Typ",
                    name: "booking_type",
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    filter: {
                        type: "select",
                        placeholder: "Auswählen",
                        options: [{"value": '+', "label": "Einnahme"}, {"value": '-', "label": "Ausgabe"}],
                        mode: 'single',
                        value: null
                    },
                },{
                    label: "Belegnummer",
                    name: "document_number",
                    filter: {
                        type: "simple",
                        placeholder: "suchen..."
                    },
                    slot_name: "document_number",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Summe netto",
                    name: "sum_netto",
                    sort: true,
                    filter: {
                        type: "simple",
                        placeholder: "suchen..."
                    },
                    slot_name: "sum_netto",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Summe brutto",
                    name: "sum_brutto",
                    sort: true,
                    filter: {
                        type: "simple",
                        placeholder: "suchen..."
                    },
                    slot_name: "sum_brutto",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false,
                },
                {
                    label: "Notiz",
                    name: "notice",
                    sort: true,
                    filter: {
                        type: "simple",
                        placeholder: "suchen..."
                    },
                    slot_name: "notice",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                }, {
                    label: "Bank zugewiesen?",
                    name: "bank_transaction_id",
                    sort: true,
                    slot_name: "bank_transaction_id",
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false,
                }, {
                    label: "Erstellt am",
                    name: "created_at",
                    sort: true,
                    filter: {
                        type: "simple",
                        placeholder: "suchen..."
                    },
                    formatter: (value) => {
                        return moment(new Date(value)).format('DD.MM.YY - HH:mm');
                    },
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false,
                },
                {
                    label: "Actions",
                    slot_name: "row_actions",
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'actions-column',
                    showCol: true,
                }],
            config: {
                checkbox_rows: false,
                rows_selectable: false,
                page: 1,
                per_page: 50,
                show_refresh_button: false,
                show_reset_button: false,
                highlight_row_hover_color: '#f8f8f8',
                server_mode: true,
                global_search: {
                    visibility: false,
                },
            },
            documentQueryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 50,
                page: 1,
            },


        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        ...mapGetters(['userHasPermission', 'userId', 'user']),
        pageSum() {

            if (!this.data || !Array.isArray(this.data)) {
                return PriceHelper.formatCurrency(0);
            }

            const sum = this.data.reduce((acc, item) => {
                if (item.booking_type === '+'){
                    return acc + Number(item.sum_netto);
                } else {
                    return acc - Number(item.sum_netto);
                }

            }, 0);

            return PriceHelper.formatCurrency(sum);
        },
        pageSumIncome() {
            if (!this.data  || !Array.isArray(this.data)) {
                return PriceHelper.formatCurrency(0);
            }

            const sum = this.data.reduce((acc, item) => {
                if (item.booking_type === '+'){
                    return acc + Number(item.sum_netto);
                } else {
                    return acc + 0;
                }

            }, 0);

            return PriceHelper.formatCurrency(sum);
        },pageSumExpenses() {
            if (!this.data  || !Array.isArray(this.data)) {
                return PriceHelper.formatCurrency(0);
            }

            const sum = this.data.reduce((acc, item) => {
                if (item.booking_type === '-'){
                    return acc - Number(item.sum_netto);
                } else {
                    return acc + 0;
                }

            }, 0);

            return PriceHelper.formatCurrency(sum);
        },
        requestDocumentParams() {
            let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);


            queryParams.filter.push({
                field: 'client_id',
                value: this.clientId,
                expression: 'exact'
            });

            queryParams.order =
                {
                    'field': 'document_date',
                    'direction': 'desc'
                }
            ;

            return queryParams;
        },
    },
    methods: {

        fetchData() {
            this.$vs.loading()
            ApiService.get('bank-transactions/document', {
                params: this.requestDocumentParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.data = response.data.result;
                this.total_rows = response.data.total;
                this.$vs.loading.close()
                this.fetchStatisticData().then(() => {
                    this.prepareChart()
                });
            }).catch(response => {
            })
        },
        fetchStatisticData() {
            this.$vs.loading()
            return ApiService.get('bank-transactions/document/statistics-history', {
                params: this.requestDocumentParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.chartData = response.data;
                this.$vs.loading.close()
            }).catch(response => {
            })
        },

        formatDate(value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
        },
        formatCurrency(number) {
            return number + ' €';
            return PriceHelper.floatPointToComma(number) + ' €';
        },
        onChangeQuery(queryParams) {
            console.log("onChangeQuery", queryParams);
            this.documentQueryParams = queryParams;
            this.fetchData();
        },
        onChangePerPage(value) {
            this.config.per_page = value;
        },
        prepareChart() {
            if (!this.chartData) {
                return;
            }

            // Chart 1 - Monthly
            this.series = []
            this.chartOptions.labels = [];
            var obj = [];

            this.chartOptions.labels = this.chartData.labels
            this.series = this.chartData.data

            this.chartOptions = {
                labels: this.chartData.labels
            };


        },

    },
    watch: {}
}
</script>

<style lang="scss">
.document-preview {
  max-width: 50px;
  -webkit-box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 21px 1px rgba(0, 0, 0, 0.42);
}

.document-zoom {
  padding: 50px;

  transition: transform .2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;

}


.document-zoom:hover img {
  transform: scale(1.5);
}
.daterangepicker {
  z-index: 9999999;
    top: 40px;
}
</style>
